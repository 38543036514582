 /*!
 *
 * base.js
 *
 */

var App = App || {};

(function() {

    /*--------------------------------------------------
      セレクトボックスのデザインを変更
    ----------------------------------------------------*/
    // $(function(){
    //     $('.custom-select').customSelect();
    // });


    /*--------------------------------------------------
      要素の高さを揃える
    ----------------------------------------------------*/
    // $(function(){
    //     $('.matchheight-item').matchHeight();
    // });


    /*--------------------------------------------------
      Google Anarytics
    ----------------------------------------------------*/
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

    ga('create', 'UA-565729-7', 'auto');
    ga('send', 'pageview');

})();