 /*!
 *
 * utility.js
 *
 */

// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());


/*--------------------------------------------------
  timer
----------------------------------------------------*/
// http://hakuhin.jp/js/timer.html
// set
// var timer = new TimerInterval(function (){
//     console.log("実行された");
// } , 1000);
// stop
// timer.stop();
var timerInterval = function(callback,delay)
{
    var timeout_id = null;

    this.stop = function ()
    {
        if(timeout_id !== null)
        {
            clearTimeout(timeout_id);
            timeout_id = null;
        }
    };

    timeout_id = setTimeout(function callee()
    {
        callback();
        if(timeout_id === null) return;
        timeout_id = setTimeout(callee,delay);
    },delay);
};

/*--------------------------------------------------
  popupWindow
----------------------------------------------------*/
function popupWindow(url, width, height, option, windowName) {
    if (!width) var width = window.innerWidth || document.documentElement.clientWidth;
    if (!height) var height = window.innerHeight || document.documentElement.clientHeight;
    if (!option) var option = 'menubar=no, titlebar=no, toolbar=no, location=no, status=no, scrollbars=yes, resizable=yes';
    if (!windowName) var windowName = "popup";
    var x = (screen.availWidth - width)/2;
    var y = (screen.availHeight - height)/2;
    var o = option+', width='+width+', height='+height+', left='+x+', top='+y;
    var blockMessage = 'ウィンドウがお使いのブラウザでポップアップブロックされました。\nポップアップブロックを解除してください。';
    var win = window.open(url, windowName, o);
    if (win) {
        win.focus();
    } else {
        alert(blockMessage);
    }
}

/*--------------------------------------------------
  画像のhover設定
----------------------------------------------------*/
function initRollovers() {
    if (!document.getElementById) { return; }
    var aPreLoad = new Array();
    var sTempSrc;
    var aImages = new Array();
    var imgs = document.getElementsByTagName('img');
    var inputs = document.getElementsByTagName('input');
    for (var i=0; i<imgs.length; i++) {
        aImages.push(imgs[i]);
    }
    for (var i=0; i<inputs.length; i++) {
        aImages.push(inputs[i]);
    }
    for (var i = 0; i < aImages.length; i++) {
        if (aImages[i].className == 'hover') {
            if (aImages[i].parentNode.nodeName == 'A' || aImages[i].type == 'image') {
                var src = aImages[i].getAttribute('src');
                var ftype = src.substring(src.lastIndexOf('.'), src.length);
                var hsrc = src.replace(ftype, '_on'+ftype);
                aImages[i].setAttribute('hsrc', hsrc);
                aPreLoad[i] = new Image();
                aPreLoad[i].src = hsrc;
                aImages[i].onmouseover = function() {
                    sTempSrc = this.getAttribute('src');
                    this.setAttribute('src', this.getAttribute('hsrc'));
                };
                aImages[i].onmouseout = function() {
                    if (!sTempSrc) { sTempSrc = this.getAttribute('src').replace('_on'+ftype, ftype) };
                    this.setAttribute('src', sTempSrc);
                };
            }
        }
    }
}
$(document).ready(initRollovers);


/*--------------------------------------------------
  ページ自動スクロール
----------------------------------------------------*/
var pageScroll = function(targetId)
{
    if(!$('#'+targetId).length)
    {
        return;
    }
    var d = new $.Deferred();
    var easingType = 'Power3.easeInOut';//easeOutQuad swing Power3.easeOut
    var duration;
    var $target = $('#'+targetId);
    var wh = window.innerHeight ? window.innerHeight : $(window).height();
    var dh = $(document).height();
    var tgtY = 0;
    var toY = 0;

    tgtY = $target.offset().top;
    if ( dh > ( tgtY + wh ) ) {
        toY = tgtY;
    } else {
        toY = tgtY - (tgtY+wh-dh);
    }

    if ($target)
    {
        duration = (1000 + Math.floor(toY/4))*0.001;
        // $('body,html').stop().animate({scrollTop: toY}, duration, easingType, function()
        // {
        //     d.resolve();
        // });
        TweenMax.to( $('body,html'), duration, {
            scrollTop: toY,
            ease : easingType,
            delay : 0,
            onComplete:function()
            {
                d.resolve();
            }
        });
    }
    return d.promise();
};

// $('a.scroll, area.scroll').not('a[href=#], area[href=#]').on('click.page-scroll', function(event)
// {
//     var targetId = this.hash.slice(1);
//     if($('#'+targetId).length)
//     {
//         event.preventDefault();
//         pageScroll(targetId);
//     }
// });

$('body').on('click.page-scroll','a.scroll, area.scroll', function(event)
{
    var targetId = this.hash.slice(1);
    if($('#'+targetId).length)
    {
        pageScroll(targetId);
        event.preventDefault();
    }
});

